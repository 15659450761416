.App {
  text-align: center;
}

.Title {
  height: 25vmin;
  pointer-events: none;
}

.smallTitle {
  height: 12vmin;
  pointer-events: none;
  position: absolute;
  top: 12%
}

.App-header {
  background-color: #FFFFFF;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'ZenMaruGothic-Regular';
}

.footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%; /* Full width */
}


.RowItems {
  display: flex;
  flex-direction: row;
  offset: 10;
}

.store-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  text-decoration: none;
  background-color: 	#f9f9f9;
  border: 1px solid transparent;
  margin: 5px;
}

.store-button:hover {
  background-color: #F87089;
}

@font-face {
  font-family: "ZenMaruGothic-Regular";
  src: local("ZenMaruGothic-Regular"),
    url("./fonts/ZenMaruGothic-Regular.ttf") format("truetype");
  font-weight: lighter;
}